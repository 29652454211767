import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { saveAs } from 'file-saver'
import Img from 'gatsby-image'

import Headline from '@objects/headline'
import Copy from '@objects/copy'
import Icon from '@objects/icon'

const StyledDownloadIcon = styled(Icon).attrs({
  name: 'Download',
  className: 'text-xl text-red',
})`
  margin-bottom: 5px;
`

// (file.file.file.details.size / (1024 * 1024)).toFixed(2)
function SingleDownload({ className, headline, copy, file, image }) {
  function downloadEvent(src) {
    saveAs(src, src.substring(src.lastIndexOf('/') + 1))
  }

  const regex = /[0-9a-z]+$/i
  const extension = file?.url?.toUpperCase().match(regex)

  return (
    <div className="py-7 px-7 md:px-10 rounded-sm bg-gray-light-300">
      <div className="flex flex-row justify-between items-center flex-wrap md:flex-no-wrap">
        <div className="flex flex-col justify-start w-full md:w-6/12 order-2 md:order-1">
          <Headline
            className="mb-2 text-lg font-medium leading-4"
            level={3}
            ariaLabel={headline}
          >
            {headline}
          </Headline>
          <Copy className="mb-4 leading-5" html={copy} />
          <button
            data-testid="download"
            className="cursor-pointer text-red font-medium mb-3 text-left"
            onClick={() => downloadEvent(file.url)}
          >
            <FormattedMessage id="download" />
            <span className="mx-0.5">
              ({extension} {(file.size / (1024 * 1024)).toFixed(2)} MB)
            </span>
            <StyledDownloadIcon />
          </button>
        </div>
        {image && (
          <Img
            className="order-1 md:order-2 w-full md:w-5/12 mb-5 md:mb-0"
            fluid={{ ...image.fluid, media: `(min-width: 0px)` }}
            alt={image.description}
          />
        )}
      </div>
    </div>
  )
}

SingleDownload.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  image: PropTypes.object,
  file: PropTypes.shape({
    url: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
  }),
}

export default SingleDownload
